import React from "react";
import { styled, media } from "../../styles";
import { Page, Content } from "../../components";
import { Trans } from "@lingui/macro";

export const LegalPage: React.FC<{}> = props => (
  <Page title="Legal" mobileHeaderFixed={true} {...props}>
    <Content>
      <Fish
        src="/videos/legal/fish.mp4"
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        controls={false}
        preload="auto"
      />
      <LegalContent>
        <LegalHeading>
          <Trans>Legal</Trans>
        </LegalHeading>

        <LegalSection id="imprint">
          <Heading as="h2">
            <Trans>Impressum</Trans>
          </Heading>

          <Copy>
            <Trans>
              <p>
                German Wahnsinn GmbH
                <br />
                Kastanienallee 9, 20359 Hamburg
                <br />
                <br />
                Registriert beim Amtsgericht Hamburg HRB 12 7352
                <br />
                mit Sitz und Gerichtsstand in Hamburg
                <br />
                <br />
                Geschäftsführer:
                <br />
                Eduardo García, Ralf Lippmann, Philipp Feit
                <br />
                <br />
                Pressekontakt:
                <br />
                <a href="mailto:presse@germanwahnsinn.de">
                  presse@germanwahnsinn.de
                </a>
                <br />
                <br />
                Umsatzsteuer-Identifikationsnr.: DE288843631
                <br />
                <br />
                Haftungshinweis:
                <br />
                Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
                Haftung für die Inhalte externer Links. Für den Inhalt der
                verlinkten Seiten sind ausschließlich deren Betreiber
                verantwortlich. Es gelten ausschließlich unsere AGB, die wir auf
                Wunsch gerne zusenden.
                <br />
                <br />
                Design:{" "}
                <a href="http://www.allerzeiten.com" target="_blank">
                  allerzeiten
                </a>
                <br />
                Programmierung:{" "}
                <a href="https://codepoet.de" target="_blank">
                  Benjamin Bender
                </a>
                <br />
                <br />
                Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV:
                <br />
                Eduardo García (Anschrift wie oben)
              </p>
            </Trans>
          </Copy>
        </LegalSection>

        <LegalSection id="privacy">
          <Heading as="h2">
            <Trans>Datenschutz</Trans>
          </Heading>

          <Trans>
            <p>
              Die Verantwortliche im Sinne der Europäischen
              Datenschutz-Grundverordnung (DSGVO) und anderer nationaler
              Datenschutzgesetze sowie sonstiger datenschutzrechtlicher
              Bestimmungen ist die:
            </p>

            <p>
              German Wahnsinn GmbH
              <br />
              Geschäftsführer: Eduardo Garcia, Ralf Lippmann, Philipp Feit
              <br />
              Kastanienallee 9<br />
              20359 Hamburg
              <br />
              Tel: +49 (0)40-32536244
              <br />
              Email:{" "}
              <a href="mailto:wahnsinn@germanwahnsinn.de">
                wahnsinn@germanwahnsinn.de
              </a>
            </p>

            <p>
              <strong>
                I. Bereitstellung unserer Internetseiten und Logfiles
              </strong>
            </p>

            <p>
              <strong>
                1. Umfang der Verarbeitung personenbezogener Daten
              </strong>
            </p>

            <p>
              Wenn Sie unsere Internetseiten aufrufen, dann stellt Ihr Browser
              eine Verbindung zu dem Webserver unseres Hosters TKR-IT, Tobias
              Kroll, Lindestr. 10, 03103 Neupetershain, her. Dabei entstehen
              Verbindungsdaten, die von TKR-IT in sog. Log-Dateien gespeichert
              werden.
            </p>

            <p>Log-Dateien enthalten die</p>

            <ul>
              <li>
                IP-Adresse des Gerätes, mit dem Sie auf unsere Internetseite
                zugreifen,
              </li>
              <li>die Art des Browsers, mit dem Sie zugreifen,</li>
              <li>Ihre Systemkonfiguration</li>
              <li>die Internetseite, die Sie zuvor besucht haben, sowie</li>
              <li>Datum und Zeitangaben Ihres Zugriffs.</li>
            </ul>

            <p>
              <strong>2. Zweck der Datenverarbeitung</strong>
            </p>

            <p>
              TKR-IT nutzt die Daten in unserem Auftrag zur Darstellung unserer
              Inhalte. Die vorübergehende Speicherung der IP-Adresse durch das
              System ist notwendig, um eine Auslieferung der Website an Ihren
              Rechner zu ermöglichen. Hierfür muss Ihre IP-Adresse für die Dauer
              der Sitzung gespeichert bleiben.
            </p>

            <p>
              <strong>3. Rechtsgrundlage für die Verarbeitung</strong>
            </p>

            <p>
              Rechtsgrundlage für die vorübergehende Speicherung der Daten und
              der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes
              Interesse ergibt sich aus dem Zweck zur Datenverarbeitung.
            </p>

            <p>
              <strong>4. Dauer der Speicherung</strong>
            </p>

            <p>
              Ihre IP-Adresse wird von TKR-IT zur Erkennung und Abwehr von
              Angriffen maximal 4 Wochen gespeichert und danach gelöscht.
            </p>

            <p>
              <strong>5. Widerspruchs- und Beseitigungsmöglichkeit</strong>
            </p>

            <p>
              Die Erfassung der Daten zur Bereitstellung der Internetseite und
              die Speicherung der Daten in Logfiles ist für den Betrieb der
              Internetseite zwingend erforderlich. Es besteht folglich seitens
              des Nutzers keine Widerspruchsmöglichkeit.
            </p>

            <p>
              <strong>II. Dienstleistungen</strong>
            </p>

            <p>
              <strong>
                1. Umfang der Verarbeitung personenbezogener Daten
              </strong>
            </p>

            <p>
              Im Zusammenhang mit von uns erbachten Dienstleistungen, die wir
              für Sie erbringen bzw. in welche Sie einbezogen sind, sowie
              diesbezüglichen vorvertraglichen Angeboten und/oder Anfragen von
              Ihnen, erheben wir
            </p>

            <ul>
              <li>
                Ihren Namen sowie die Firma bzw. den Namen des Unternehmens für
                das sie tätig sind
              </li>
              <li>
                Ihre Adresse mit Straße, Postleitzahl, Stadt, ggf. Bundesland
                und Land.
              </li>
              <li>
                Ihre Telefonnummer, Ihre Faxnummer sowie Ihre Emailadresse
                soweit vorhanden
              </li>
              <li>
                Informationen über die Angebote, Anfragen bzw. erbrachten
                Dienstleistungen
              </li>
            </ul>

            <p>
              Eine Weitergabe Ihrer angegebenen personenbezogenen Daten von uns
              an Dritte erfolgt ausschließlich im Rahmen der Bearbeitung einer
              vorvertraglichen Anfrage bzw. eines Angebotes durch Sie und/oder
              einer Vertragsabwicklung, in welche Sie einbezogen sind.{" "}
            </p>

            <p>
              <strong>2. Zweck der Datenverarbeitung</strong>
            </p>

            <p>
              Die Erhebung und Speicherung und Weitergabe der Daten erfolgt, um
              Sie identifizieren zu können, um eine Anfrage oder ein Angebot von
              Ihnen und/oder eine vertragliche Zusammenarbeit mit Ihnen zu
              bearbeiten, zu erfüllen und abwickeln zu können sowie um etwaige
              Korrespondenz oder Telefonate diesbezüglich mit Ihnen zu führen.
            </p>

            <p>
              <strong>3. Rechtsgrundlage für die Verarbeitung</strong>
            </p>

            <p>
              Die Datenverarbeitung erfolgt nach Art. 6 Abs. 1 lit b DSGVO,
              nämlich zur Erfüllung eines Vertrags, dessen Vertragspartei Sie
              sind, oder zur Durchführung vorvertraglicher Maßnahmen, die auf
              Anfrage bzw. ein Angebot von Ihnen erfolgen.
            </p>

            <p>
              <strong>4. Dauer der Speicherung</strong>
            </p>

            <p>
              Im Fall einer vertraglichen Zusammenarbeit, speichern wir die
              Daten bis zum Ablauf der gesetzlichen Aufbewahrungspflicht. Sofern
              es zu keiner Zusammenarbeit kommt, werden die Daten aus
              vorvertraglichen Maßnahmen unverzüglich gelöscht.
            </p>

            <p>
              <strong>5. Widerspruchs- und Beseitigungsmöglichkeit</strong>
            </p>

            <p>
              Eine Löschung ist nur möglich, soweit nicht vertragliche und
              gesetzliche Verpflichtungen einer Löschung entgegenstehen.
            </p>

            <p>
              <strong>III. E-Mail-Kontakt</strong>
            </p>

            <p>
              <strong>1. Beschreibung und Umfang der Datenverarbeitung</strong>
            </p>

            <p>
              Sie können über die bereitgestellten E-Mail-Adressen Kontakt mit
              uns aufnehmen. In diesem Fall werden die mit der E-Mail
              übermittelten personenbezogenen Daten gespeichert.
            </p>

            <p>
              Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an
              Dritte. Die Daten werden ausschließlich für die Verarbeitung der
              Konversation verwendet.
            </p>

            <p>
              <strong>2. Rechtsgrundlage für die Datenverarbeitung </strong>
            </p>

            <p>
              Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
              Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1
              lit. f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines
              Vertrages ab, so ist zusätzliche Rechtsgrundlage für die
              Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
            </p>

            <p>
              <strong>3. Zweck der Datenverarbeitung</strong>
            </p>

            <p>
              Die Verarbeitung der personenbezogenen Daten aus der Email dient
              uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer
              Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche
              berechtigte Interesse an der Verarbeitung der Daten.
            </p>

            <p>
              <strong>4. Dauer der Speicherung</strong>
            </p>

            <p>
              Die Daten werden gelöscht, sobald sie für die Erreichung des
              Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die
              personenbezogenen Daten, die per E-Mail übersandt wurden, ist dies
              dann der Fall, wenn die jeweilige Konversation mit Ihnen beendet
              ist. Beendet ist die Konversation dann, wenn sich aus den
              Umständen entnehmen lässt, dass der betroffene Sachverhalt
              abschließend geklärt ist.
            </p>

            <p>
              <strong>5. Widerspruchs- und Beseitigungsmöglichkeit</strong>
            </p>

            <p>
              Sie haben jederzeit die Möglichkeit, durch E-Mail an{" "}
              <u>wahnsinn@germanwahnsinn.de</u> Ihre Einwilligung zur
              Verarbeitung der personenbezogenen Daten zu widerrufen. Nehmen Sie
              per E-Mail Kontakt mit uns auf, so können Sie der Speicherung
              Ihrer personenbezogenen Daten jederzeit widersprechen. In einem
              solchen Fall kann die Konversation nicht fortgeführt werden. Alle
              personenbezogenen Daten, die im Zuge der Kontaktaufnahme
              gespeichert wurden, werden in diesem Fall gelöscht.
            </p>

            <p>
              <strong>IV. Eingebundene Videos</strong>
            </p>

            <p>
              Wir haben auf unserer Internetseite Videos zu unseren Produkten
              eingebunden. Hierzu bedienen wir uns eines Dienstleisters, nämlich
              der Plattform “Vimeo” des Anbieters Vimeo Inc., Attention: Legal
              Department, 555 West 18th Street New York, New York 10011, USA.
              Datenschutzerklärung:{" "}
              <a href="https://vimeo.com/privacy" target="_blank">
                https://vimeo.com/privacy
              </a>
              . Um die Verbindung zu „Vimeo“ technisch aufbauen zu können, wird
              Ihre IP-Adresse an „Vimeo“ übermittelt.
            </p>

            <p>
              Vimeo setzt zudem in eigener Verantwortung Google Analytics zu
              Analysezwecken ein. Wir haben darauf weder Einfluss noch Zugriff.
              Dabei werden Cookies von Google Analytics gesetzt. Weitere
              Informationen dazu finden Sie in deren Datenschutzerklärung (
              <a href="https://www.google.com/policies/privacy" target="_blank">
                https://www.google.com/policies/privacy
              </a>
              ). Unter (
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              ) erfahren Sie, wie Sie das Setzen von Cookies durch Google
              Analytics unterbinden können.
            </p>

            <p>
              Durch das Aufrufen der bereitgehaltenen Videos erklären Sie Ihre
              Einwilligung in die vorstehende Verarbeitung Ihrer Daten. Ein
              Aufruf der Videos ist überdies ohne die Verarbeitung Ihrer Daten
              technisch nicht möglich.
            </p>

            <p>
              <strong>2. Rechtsgrundlage für die Datenverarbeitung </strong>
            </p>

            <p>
              Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen
              einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
            </p>

            <p>
              <strong>3. Zweck der Datenverarbeitung</strong>
            </p>

            <p>
              Die Verarbeitung der personenbezogenen Daten dient uns allein zur
              Darstellung der Videos mit unseren Produkten sowie Ihnen die
              Möglichkeit deren Aufrufs zu geben.
            </p>

            <p>
              <strong>4. Dauer der Speicherung</strong>
            </p>

            <p>
              Von uns erhobene Daten werden gelöscht, sobald sie für die
              Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich
              sind.
            </p>

            <p>
              <strong>5. Widerspruchs- und Beseitigungsmöglichkeit</strong>
            </p>

            <p>
              Sie haben jederzeit die Möglichkeit, Ihre Einwilligung zur
              Verarbeitung der personenbezogenen Daten zu widerrufen.
            </p>

            <p>
              <strong>V.</strong> <strong>Ihre Rechte</strong>
            </p>

            <p>
              Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
              Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte
              gegenüber dem Verantwortlichen zu:
            </p>

            <p>
              <strong>1. Auskunftsrecht</strong>
            </p>

            <p>
              Sie können von dem Verantwortlichen eine Bestätigung darüber
              verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
              verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie
              von dem Verantwortlichen über folgende Informationen Auskunft
              verlangen:
            </p>

            <p>
              (1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet
              werden;
            </p>

            <p>
              (2) die Kategorien von personenbezogenen Daten, welche verarbeitet
              werden;
            </p>

            <p>
              (3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber
              denen die Sie betreffenden personenbezogenen Daten offengelegt
              wurden oder noch offengelegt werden;
            </p>

            <p>
              (4) die geplante Dauer der Speicherung der Sie betreffenden
              personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
              möglich sind, Kriterien für die Festlegung der Speicherdauer;
            </p>

            <p>
              (5) das Bestehen eines Rechts auf Berichtigung oder Löschung der
              Sie betreffenden personenbezogenen Daten, eines Rechts auf
              Einschränkung der Verarbeitung durch den Verantwortlichen oder
              eines Widerspruchsrechts gegen diese Verarbeitung;
            </p>

            <p>
              (6) das Bestehen eines Beschwerderechts bei einer
              Aufsichtsbehörde;
            </p>

            <p>
              (7) alle verfügbaren Informationen über die Herkunft der Daten,
              wenn die personenbezogenen Daten nicht bei der betroffenen Person
              erhoben werden;
            </p>

            <p>
              (8) das Bestehen einer automatisierten Entscheidungsfindung
              einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und –
              zumindest in diesen Fällen – aussagekräftige Informationen über
              die involvierte Logik sowie die Tragweite und die angestrebten
              Auswirkungen einer derartigen Verarbeitung für die betroffene
              Person.
            </p>

            <p>
              Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die
              Sie betreffenden personenbezogenen Daten in ein Drittland oder an
              eine internationale Organisation übermittelt werden. In diesem
              Zusammenhang können Sie verlangen, über die geeigneten Garantien
              gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung
              unterrichtet zu werden.
            </p>

            <p>
              <strong>2. Recht auf Berichtigung </strong>
            </p>

            <p>
              Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
              gegenüber dem Verantwortlichen, sofern die verarbeiteten
              personenbezogenen Daten, die Sie betreffen, unrichtig oder
              unvollständig sind. Der Verantwortliche hat die Berichtigung
              unverzüglich vorzunehmen.
            </p>

            <p>
              <strong>3. Recht auf Einschränkung der Verarbeitung</strong>
            </p>

            <p>
              Unter den folgenden Voraussetzungen können Sie die Einschränkung
              der Verarbeitung der Sie betreffenden personenbezogenen Daten
              verlangen:
            </p>

            <p>
              (1) wenn Sie die Richtigkeit der Sie betreffenden
              personenbezogenen für eine Dauer bestreiten, die es dem
              Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen
              Daten zu überprüfen;
            </p>

            <p>
              (2) die Verarbeitung unrechtmäßig ist und Sie die Löschung der
              personenbezogenen Daten ablehnen und stattdessen die Einschränkung
              der Nutzung der personenbezogenen Daten verlangen;
            </p>

            <p>
              (3) der Verantwortliche die personenbezogenen Daten für die Zwecke
              der Verarbeitung nicht länger benötigt, Sie diese jedoch zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              benötigen, oder
            </p>

            <p>
              (4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs.
              1 DSGVO eingelegt haben und noch nicht feststeht, ob die
              berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
              überwiegen.
            </p>

            <p>
              Wurde die Verarbeitung der Sie betreffenden personenbezogenen
              Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung
              abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
              der Rechte einer anderen natürlichen oder juristischen Person oder
              aus Gründen eines wichtigen öffentlichen Interesses der Union oder
              eines Mitgliedstaats verarbeitet werden. Wurde die Einschränkung
              der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt,
              werden Sie von dem Verantwortlichen unterrichtet bevor die
              Einschränkung aufgehoben wird.
            </p>

            <p>
              <strong>4. Recht auf Löschung</strong>
            </p>

            <p>
              <strong>a. Löschungspflicht</strong>
            </p>

            <p>
              Sie können von dem Verantwortlichen verlangen, dass die Sie
              betreffenden personenbezogenen Daten unverzüglich gelöscht werden,
              und der Verantwortliche ist verpflichtet, diese Daten unverzüglich
              zu löschen, sofern einer der folgenden Gründe zutrifft:
            </p>

            <p>
              (1) Die Sie betreffenden personenbezogenen Daten sind für die
              Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet
              wurden, nicht mehr notwendig.
            </p>

            <p>
              (2) Sie widerrufen Ihre Einwilligung, auf die sich die
              Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a
              DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage
              für die Verarbeitung.
            </p>

            <p>
              (3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
              Verarbeitung ein und es liegen keine vorrangigen berechtigten
              Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs.
              2 DSGVO Widerspruch gegen die Verarbeitung ein.
            </p>

            <p>
              (4) Die Sie betreffenden personenbezogenen Daten wurden
              unrechtmäßig verarbeitet.
            </p>

            <p>
              (5) Die Löschung der Sie betreffenden personenbezogenen Daten ist
              zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht
              oder dem Recht der Mitgliedstaaten erforderlich, dem der
              Verantwortliche unterliegt.
            </p>

            <p>
              (6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug
              auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8
              Abs. 1 DSGVO erhoben.
            </p>

            <p>
              <strong>b. Information an Dritte</strong>
            </p>

            <p>
              Hat der Verantwortliche die Sie betreffenden personenbezogenen
              Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu
              deren Löschung verpflichtet, so trifft er unter Berücksichtigung
              der verfügbaren Technologie und der Implementierungskosten
              angemessene Maßnahmen, auch technischer Art, um für die
              Datenverarbeitung Verantwortliche, die die personenbezogenen Daten
              verarbeiten, darüber zu informieren, dass Sie als betroffene
              Person von ihnen die Löschung aller Links zu diesen
              personenbezogenen Daten oder von Kopien oder Replikationen dieser
              personenbezogenen Daten verlangt haben.
            </p>

            <p>
              <strong>c. Ausnahmen</strong>
            </p>

            <p>
              Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
              erforderlich ist
            </p>

            <p>
              (1) zur Ausübung des Rechts auf freie Meinungsäußerung und
              Information;
            </p>

            <p>
              (2) zur Erfüllung einer rechtlichen Verpflichtung, die die
              Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten,
              dem der Verantwortliche unterliegt, erfordert, oder zur
              Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt
              oder in Ausübung öffentlicher Gewalt erfolgt, die dem
              Verantwortlichen übertragen wurde;
            </p>

            <p>
              (3) aus Gründen des öffentlichen Interesses im Bereich der
              öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie
              Art. 9 Abs. 3 DSGVO;
            </p>

            <p>
              (4) für im öffentlichen Interesse liegende Archivzwecke,
              wissenschaftliche oder historische Forschungszwecke oder für
              statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
              Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
              Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
              beeinträchtigt, oder
            </p>

            <p>
              (5) zur Geltendmachung, Ausübung oder Verteidigung von
              Rechtsansprüchen.
            </p>

            <p>
              <strong>5. Recht auf Unterrichtung</strong>
            </p>

            <p>
              Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung
              der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht,
              ist dieser verpflichtet, allen Empfängern, denen die Sie
              betreffenden personenbezogenen Daten offengelegt wurden, diese
              Berichtigung oder Löschung der Daten oder Einschränkung der
              Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
              unmöglich oder ist mit einem unverhältnismäßigen Aufwand
              verbunden. Ihnen steht gegenüber dem Verantwortlichen das Recht
              zu, über diese Empfänger unterrichtet zu werden.
            </p>

            <p>
              <strong>6. Recht auf Datenübertragbarkeit</strong>
            </p>

            <p>
              Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
              die Sie dem Verantwortlichen bereitgestellt haben, in einem
              strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
              Außerdem haben Sie das Recht diese Daten einem anderen
              Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
              die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
              sofern
            </p>

            <p>
              (1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1
              lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem
              Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
            </p>

            <p>
              (2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
            </p>

            <p>
              In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken,
              dass die Sie betreffenden personenbezogenen Daten direkt von einem
              Verantwortlichen einem anderen Verantwortlichen übermittelt
              werden, soweit dies technisch machbar ist. Freiheiten und Rechte
              anderer Personen dürfen hierdurch nicht beeinträchtigt werden. Das
              Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
              personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
              erforderlich ist, die im öffentlichen Interesse liegt oder in
              Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
              übertragen wurde.
            </p>

            <p>
              <strong>7. Widerspruchsrecht</strong>
            </p>

            <p>
              Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
              Situation ergeben, jederzeit gegen die Verarbeitung der Sie
              betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs.
              1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
              auch für ein auf diese Bestimmungen gestütztes Profiling. Der
              Verantwortliche verarbeitet die Sie betreffenden personenbezogenen
              Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige
              Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
              Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            </p>

            <p>
              Werden die Sie betreffenden personenbezogenen Daten verarbeitet,
              um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
              Widerspruch gegen die Verarbeitung der Sie betreffenden
              personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
              dies gilt auch für das Profiling, soweit es mit solcher
              Direktwerbung in Verbindung steht.
            </p>

            <p>
              Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung,
              so werden die Sie betreffenden personenbezogenen Daten nicht mehr
              für diese Zwecke verarbeitet.
            </p>

            <p>
              Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
              Diensten der Informationsgesellschaft – ungeachtet der Richtlinie
              2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter
              Verfahren auszuüben, bei denen technische Spezifikationen
              verwendet werden.
            </p>

            <p>
              <strong>
                8. Recht auf Widerruf der datenschutzrechtlichen
                Einwilligungserklärung
              </strong>
            </p>

            <p>
              Sie haben das Recht, Ihre datenschutzrechtliche
              Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf
              der Einwilligung wird die Rechtmäßigkeit der aufgrund der
              Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
              berührt.
            </p>

            <p>
              <strong>
                9. Automatisierte Entscheidung im Einzelfall einschließlich
                Profiling
              </strong>
            </p>

            <p>
              Sie haben das Recht, nicht einer ausschließlich auf einer
              automatisierten Verarbeitung – einschließlich Profiling –
              beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber
              rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
              beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
            </p>

            <p>
              (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen
              Ihnen und dem Verantwortlichen erforderlich ist,
            </p>

            <p>
              (2) aufgrund von Rechtsvorschriften der Union oder der
              Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig
              ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung
              Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen
              enthalten oder
            </p>

            <p>(3) mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>

            <p>
              Allerdings dürfen diese Entscheidungen nicht auf besonderen
              Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO
              beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und
              angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie
              Ihrer berechtigten Interessen getroffen wurden. Hinsichtlich der
              in (1) und (3) genannten Fälle trifft der Verantwortliche
              angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre
              berechtigten Interessen zu wahren, wozu mindestens das Recht auf
              Erwirkung des Eingreifens einer Person seitens des
              Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
              Anfechtung der Entscheidung gehört.
            </p>

            <p>
              <strong>
                10. Recht auf Beschwerde bei einer Aufsichtsbehörde
              </strong>
            </p>

            <p>
              Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
              gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde
              bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
              Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des
              mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die
              Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
              die DSGVO verstößt. Die Aufsichtsbehörde, bei der die Beschwerde
              eingereicht wurde, unterrichtet den Beschwerdeführer über den
              Stand und die Ergebnisse der Beschwerde einschließlich der
              Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
            </p>
          </Trans>
        </LegalSection>

        <LegalSection id="terms">
          <Heading as="h2">
            <Trans>AGB</Trans>
          </Heading>

          <Copy>
            <Trans>
              <p>I. ALLGEMEINES/VERTRAGSABSCHLUSS/PRÄSENTATIONEN</p>

              <p>
                1. Diese AGB der German Wahnsinn GmbH (nachfolgend „German
                Wahnsinn“) gelten für alle von German Wahnsinn übernommenen
                Aufträge in den Bereichen Musikberatung, Musikrecherche,
                Konzeption, Komposition, Bearbeitung bestehender Werke,
                Arrangement, Layout, Produktion und Tonstudio-Arbeiten, soweit
                nicht im Einzelfall Abweichendes schriftlich vereinbart wurde.
              </p>
              <p>
                Geschäftsbedingungen des Auftraggebers werden nicht
                Vertragsinhalt, auch wenn German Wahnsinn diesen nicht
                ausdrücklich widerspricht. Dies gilt auch dann, wenn German
                Wahnsinn in Kenntnis entgegenstehender oder abweichender
                Einkaufsbedingungen des Auftraggebers Lieferungen und/oder
                Leistungen vorbehaltlos ausführt.
              </p>
              <p>
                2. Auftraggeber ist, wer die Durchführung der Produktion,
                schriftlich oder mündlich, veranlasst hat, auch wenn die
                Erteilung der Rechnung auf seinen Wunsch an einen Dritten
                erfolgt, d.h. Auftraggeber haftet voll neben dem Dritten für den
                Rechnungsbetrag.
              </p>
              <p>
                3. Für German Wahnsinn besteht die Verpflichtung zu einer
                schriftlichen Auftragsbestätigung nur dann, wenn dies vom
                Auftraggeber ausdrücklich verlangt wird.
              </p>
              <p>
                4. Für die Teilnahme an Präsentationen und Pitches steht der
                German Wahnsinn ein angemessenes Honorar zu, das zumindest den
                gesamten Personal- und Sachaufwand der German Wahnsinn für die
                Präsentation sowie die Kosten sämtlicher Fremdleistungen deckt.
                In jedem Fall bleiben die Präsentationsunterlagen der German
                Wahnsinn in ihrem Eigentum. Auf Aufforderung hat der
                Auftraggeber sämtliche Präsentationsunterlagen unverzüglich
                zurückzugeben. Der Auftraggeber ist in keinem Fall berechtigt,
                die von German Wahnsinn erstellten Präsentationsunterlagen
                weiter zu nutzen, insbesondere zu bearbeiten, an Dritte
                weiterzugeben, zu veröffentlichen oder zu vervielfältigen. Das
                gilt auch bei Zahlung eines Präsentationshonorars durch den
                Auftraggeber. German Wahnsinn bleibt in jedem Fall berechtigt,
                die im Zuge der Präsentation erbrachten urheberrechtlich
                geschützten Leistungen anderweitig zu nutzen.
              </p>

              <p>II. URHEBERRECHTLICHE NUTZUNGSRECHTE/ LEISTUNGSSCHUTZRECHTE</p>

              <p>
                Ist Gegenstand des Auftrages die Bearbeitung oder Verwendung
                geschützter Werke oder Sprache, so obliegt es dem Auftraggeber,
                die notwendige Erlaubnis des Originalurhebers einzuholen.
                Rechte, die die Urheber von Werken an die GEMA und/oder
                Musikverlage übertragen haben, sind nicht übertragbar und werden
                daher nicht durch Zahlungen an German Wahnsinn abgegolten; der
                Auftraggeber verpflichtet sich folglich, alle insoweit
                anfallenden Gebühren für die mechanische Vervielfältigung,
                öffentliche Aufführung etc. im Verhältnis zu den
                Verwertungsgesellschaften (wie bspw. der GEMA und der GVL),
                Musikverlagen und/oder Urhebern ordnungsgemäß zu entrichten.
                German Wahnsinn ist im Falle der Verwendung vom Auftraggeber zu
                Verfügung gestellten Materialien nicht verpflichtet
                nachzuprüfen, inwieweit der Inhalt bestellter Arbeiten gegen
                gesetzliche Vorschriften verstößt. Ist dies der Fall, haftet der
                Auftraggeber für alle daraus entstehenden Nachteile oder Schäden
                und hält German Wahnsinn frei, insbesondere von Ansprüchen
                Dritter und angemessenen Anwaltskosten.
              </p>

              <p>III. GEWÄHRLEISTUNG/NACHBESSERUNG/HAFTUNG</p>

              <p>
                1. Für den Fall, dass der Auftrag die Durchführung der
                Werkaufnahme als Produktionsbeauftragter umfasst, ist German
                Wahnsinn verpflichtet, einen technisch einwandfreien geeigneten
                Tonträger abzuliefern. Terminzusagen der German Wahnsinn zu
                Bearbeitungs- und Produktionsvorgängen gelten nur bei
                ausdrücklicher schriftlicher Bestätigung als verbindlich.
              </p>
              <p>
                2. Der Auftraggeber ist verpflichtet, alle Leistungen von German
                Wahnsinn unverzüglich nach Übergabe durch einen qualifizierten
                Mitarbeiter untersuchen zu lassen. Mängelrügen müssen
                schriftlich unter genauer Beschreibung der Beanstandung erfolgen
                und spätestens innerhalb von zwei Wochen nach Übergabe des
                Werkes an den Auftraggeber eingegangen sein. Danach gilt das
                Werk in Bezug auf offene, dh. sichtbare Mängel als vertragsgemäß
                und mängelfrei geschaffen. Für nicht erkennbare Mängel gilt die
                gesetzliche Verjährungsfrist.
              </p>
              <p>
                3. Jegliche Gewährleistung erlischt, wenn der Auftraggeber ohne
                ausdrückliche schriftliche Zustimmung des/der German Wahnsinn
                Eingriffe in das Werk vornimmt oder vornehmen lässt, sofern
                durch diese Eingriffe ein Fehler und/oder Schaden entsteht
                und/oder soweit hierdurch ein Fehler und/oder Schaden verstärkt
                wird. Den Beweis dafür, dass der Schaden nicht durch den von ihm
                vorgenommenen/veranlassten Eingriff verursacht bzw. verstärkt
                wurde, hat der Auftraggeber zu führen.
              </p>
              <p>
                4. Sind im Verlaufe einer Auftragsdurchführung Fremdleistungen
                erforderlich, d.h. Leistungen, die nicht mit den eigenen Geräten
                und dem eigenen Personal der German Wahnsinn durchführbar sind,
                so vermittelt German Wahnsinn gerne derartige Leistungen an
                dritte Unternehmen. Für deren Qualität, Pünktlichkeit und Kosten
                dieser Leistungen ist German Wahnsinn dann nicht verantwortlich.
                Auf Wunsch des Auftraggebers übernimmt German Wahnsinn jedoch
                nach bestem Wissen und Gewissen die Organisation solcher
                Fremdleistungen gegen den branchenüblichen Aufschlag und
                verauslagt die entsprechenden Kosten (zB. Gagen für Sprecher,
                Darsteller, Porto, Nachnahmen, Telefonate, Taxen, Kurierdienste
                etc.). German Wahnsinn behält sich vor, bei unzumutbar hohen
                Barverauslagungen die Auslieferung der Produktion von der
                Rückerstattung verauslagter Beträge durch den Auftraggeber
                abhängig zu machen.
              </p>
              <p>
                5. German Wahnsinn haftet unbeschränkt im Falle von Vorsatz und
                grober Fahrlässigkeit. German Wahnsinn haftet im Falle einfacher
                Fahrlässigkeit nur bei der Verletzung von wesentlichen
                Vertragspflichten (soge-nannten „Kardinalpflichten“), jedoch
                begrenzt auf den vertragstypischen und vorhersehbaren Schaden.
                Im Falle der Verletzung des Lebens, des Körpers oder der
                Gesundheit haftet German Wahnsinn auch bei Vorliegen einfacher
                Fahrlässigkeit unbeschränkt. Soweit die Haftung der German
                Wahnsinn gemäß dieser Ziffer ausgeschlossen oder begrenzt ist,
                gilt dies auch für die persönliche Haftung ihrer Erfüllungs- und
                Verrichtungsgehilfen.
              </p>

              <p>IV. GEHEIMHALTUNG</p>

              <p>
                Beide Parteien verpflichten sich zeitlich unbegrenzt, bei
                Durchführung eines Auftrages größtmögliche Sorgfalt walten zu
                lassen und über die ihnen bekannt gewordenen Informationen und
                Geschäftsgeheimnisse, die ihnen im Zusammenhang mit der
                Vertragsdurchführung bekannt werden, auch nach Beendigung ihrer
                Geschäftsbeziehung Stillschweigen zu bewahren. Die Weitergabe an
                nicht mit der Durchführung des Vertrages beschäftigte Dritte
                darf nur mit schriftlicher Einwilligung der jeweils anderen
                Partei erfolgen. Als vertrauliche Informationen gelten neben
                ausdrücklich als vertraulich gekennzeichnete Informationen auch
                solche Informationen, bei denen sich ein Geheimhaltungsinteresse
                einer Partei aus den Umständen ergibt. Hierzu zählen
                insbesondere die Preisvereinbarungen, die zwischen den Parteien
                getroffen wurden. Die Parteien verwahren und sichern diese
                Informationen so, dass die Kenntnisnahmemöglichkeit durch Dritte
                ausgeschlossen ist.
              </p>

              <p>V. AUFTRAGSBESTÄTIGUNG</p>

              <p>
                Auftragsbestätigungen erbittet German Wahnsinn in dreifacher
                Ausfertigung mit Angabe der Agentur-Auftragsnummer, der
                Kundennummer und des Datums.
              </p>

              <p>VI. AUFBEWAHRUNG VON UNTERLAGEN/DATEN</p>

              <p>
                Wir gewährleisten in der Regel die Archivierung sämtlicher Daten
                und Mischelemente auf Master-, Mehrspurbändern und allen
                Datenträgern bis drei Monate nach Produktionsabschluss.
              </p>
              <p>
                Bei allgemeinen Unterlagen gilt eine Aufbewahrung von drei
                Jahren. Sollte die Aufbewahrungszeit die drei Jahre auf Wunsch
                des Auftraggebers überschreiten, so hat der Auftraggeber für
                jedes weitere Jahr eine Aufbewahrungspauschale zu leisten, deren
                Höhe nach Umfang im einzelnen gesondert berechnet wird.
              </p>

              <p>VII. VERSAND</p>

              <p>
                Versendung und Transport von Material aller Art erfolgen auf
                Rechnung und Gefahr des Auftraggebers.
              </p>

              <p>VIII. ZAHLUNGSBEDINGUNGEN/AUFSCHLÄGE</p>

              <p>
                1. Für die Lieferung gelten die Preise zum Zeitpunkt der
                Auftragserteilung oder Auftragsbestätigung. Unsere Preise
                verstehen sich zuzüglich der gesetzlichen Mehrwertsteuer, soweit
                nicht schriftlich eine andere Vereinbarung getroffen wurde. In
                der Regel verstehen sich die Preise incl. Personal sowie
                Material bei Spot-Produktionen sowie excl. Material bei
                Studioabrechnungen.
              </p>
              <p>
                2. Unsere Rechnungen sind sofort fällig und netto ohne Abzug
                zahlbar. Eine Zahlung gilt erst dann als erfolgt, wenn German
                Wahnsinn über den Betrag verfügen kann. Im Falle eines
                Zahlungsverzuges sind wir berechtigt, Verzugszinsen in Höhe von
                acht Prozentpunkten über dem Basiszinssatz zu berechnen. Die
                Geltendmachung eines darüber hinausgehenden Verzugschadens
                bleibt German Wahnsinn vorbehalten; dem Auftraggeber bleibt der
                Nachweis eines niedrigeren Verzugsschadens vorbehalten.
              </p>
              <p>
                3. Die Verwendung einer Werkaufnahme gilt erst dann als
                zulässig, wenn die Rechnung an German Wahnsinn vollständig
                bezahlt ist. Insofern erfolgt die Rechtseinräumung an den
                Auftraggeber aufschiebend bedingt.
              </p>
              <p>
                4. Übliche Geschäftszeiten sind Montags bis Freitags von 9.00
                bis 18.00 Uhr; Produktionen sind jedoch auch nach Vereinbarung
                Samstags, Sonn- und feiertags möglich. Es werden folgende
                Aufschläge für Produktionen bzw. Leistungen außerhalb der
                üblichen Geschäftszeiten berechnet:
              </p>
              <p>
                18.00 - 20.00 Uhr = zzgl. 20%; ab 20.00 Uhr = zzgl. 50%; Sonn-
                u. Feiertags = zuzüglich 50%
              </p>

              <p>IX. RECHNUNGSREKLAMATIONEN</p>

              <p>
                Rechnungsreklamationen sind innerhalb von acht Tagen nach
                Rechnungserhalt der German Wahnsinn schriftlich anzuzeigen.
              </p>

              <p>
                X. BESONDERE BEDINGUNGEN FÜR DIE BEREICHE MUSIKBERATUNG,
                MUSIKRECHERCHE, KONZEPTION, KOMPOSITION ARRANGEMENT, LAYOUT,
                PRODUKTION UND BEARBEITUNG VON BESTEHENDEN WERKEN
              </p>

              <p>A. ALLGEMEINES</p>

              <p>
                1. &quot;Komposition&quot; im Sinne der AGB dieser
                Geschäftsbedingungen sind sämtliche Werke des Komponisten,
                gleich in welcher Schaffenstufe oder in welcher technischen Form
                sie vorliegen (Notiertes Motiv, Partitur, Layout oder Produktion
                auf Ton und/oder Bildtonträger).
              </p>
              <p>
                2. Die Herstellung von Layouts (Demonstrationsaufnahmen) oder
                die Durchführung der Werkaufnahme als Produktionsbeauftragter
                sind eigenständige Leistungen des Komponisten. Sie können von
                ihm gesondert in Rechnung gestellt werden, soweit sie in dem
                erteilten Auftrag nicht enthalten sind und vom Auftraggeber
                zusätzlich gewünscht werden. Nutzungsrechte an Layouts werden
                nicht übertragen.
              </p>
              <p>
                3. Durch den Auftrag anfallende Nebenkosten (z.B. Honorare für
                Sprecher und/oder Vokalisten, Bildsynchronisation,
                Spezialgeräteverleih, Reisekosten, Spesen) gehen zu Lasten des
                Auftraggebers. Sprecher, Vokalisten und/oder Musiker werden
                ausschließlich namens, im Auftrag und für Rechnung des
                Auftraggebers engagiert.
              </p>
              <p>
                4. Alle vom Komponisten berechneten Honorare und sonstigen
                Entgelte verstehen sich zuzüglich der jeweils bei
                Vertragsabschluss geltenden gesetzlichen Mehrwertsteuer, soweit
                nicht schriftlich eine andere Vereinbarung getroffen wurde.
              </p>

              <p>B. URHEBERRECHTLICHE NUTZUNGSRECHTE/LEISTUNGSSCHUTZRECHTE</p>

              <p>
                1. Der Komponist überträgt die Nutzungsrechte frei von
                Ansprüchen Dritter, insbesondere auch frei von
                persönlichkeitsrechtlichen Einwendungen und Vergütungsansprüchen
                Dritter. Der Komponist bleibt berechtigt, die Komposition für
                eigene Demonstrationszwecke (Showreel, Demotape) zu verwerten.
                Sollte eine Komposition 12 Monate nach Abnahme nicht
                veröffentlicht worden sein, ist der Komponist berechtigt, dem
                Auftraggeber eine angemessene Nachfrist zur Veröffentlichung von
                mindestens 3 (drei) Wochen zu setzen. Verstreicht auch diese
                Nachfrist fruchtlos, fallen die Rechte mit Ablauf der Nachfrist
                auf den Komponisten zur ausschließlichen Verwertung zurück.
                Vergütungsansprüche des Komponisten nach Maßgabe der mit dem
                Auftraggeber getroffenen Vereinbarung bleiben unberührt von
                einem solchen Rechterückfall.
              </p>
              <p>
                2. Für den Fall, dass der Auftrag die Durchführung der
                Werkaufnahme als Produktionsbeauftragter umfasst, schließen die
                an den Auftraggeber übertragenen Rechte sämtliche dem
                Komponisten selbst, den beteiligten Interpreten und den
                sonstigen Mitwirkenden an der Werkaufnahme zustehenden
                übertragbaren Rechte und Ansprüche, insbesondere sämtliche
                Leistungsschutzrechte ein. Unberührt bleiben die Ansprüche, die
                der Komponist, die ausübenden Künstler und sonstigen
                Leistungsschutzberechtigten aufgrund ihrer Verträge mit der GEMA
                bzw. GVL haben.
              </p>
              <p>
                3. Der Komponist räumt dem Auftraggeber urheberrechtliche
                Nutzungsrechte ein zum vertraglich vereinbarten Zweck. Die
                Einräumung darüber hinausgehender Nutzungsrechte (inhaltlich,
                zeitlich, räumlich) bedarf für jeden Einzelfall einer besonderen
                schriftlichen Vereinbarung. Ohne vorheriger schriftlicher
                Einwilligung des Komponisten ist der Auftraggeber insbesondere
                nicht berechtigt, die Komposition umzugestalten, zu bearbeiten,
                neu aufzunehmen oder mit Bild-, Text- und/oder Tonmaterial eines
                anderen Produktes als dem vertraglich vereinbarten zu
                synchronisieren.
              </p>
              <p>
                4. Bei der Verwendung seines Werkes hat der Komponist Anspruch,
                in von ihm nach billigem Ermessen zu bestimmender,
                branchenüblicher Weise als Urheber bezeichnet zu werden
                (Beschriftung sämtlicher Sendekopien, Copyright- Vermerk im
                Vor-oder Abspann bei Bildtonträgern u.ä.). Der Auftraggeber ist
                ohne schriftliche Mitteilung an den Komponisten nicht
                berechtigt, die vom Komponisten angegebene Werkbezeichnung bzw.
                den vom Komponisten verwendeten bzw. angemeldeten Titel einer
                Produktion zu verändern. Der Auftraggeber erfüllt für die von
                ihm vorgenommene oder beauftragte Vervielfältigung und
                Verbreitung der Komposition anfallenden gesetzlichen oder
                vertraglichen Urheberrechtsverbindlichkeiten; insbesondere
                verpflichtet sich der Auftraggeber auch im Namen seiner dritten
                Vertragspartner zur ordnungs- und fristgemäßen Entrichtung aller
                im Zusammenhang mit einer Verwertung der Komposition anfallenden
                urheberrechtlichen Nutzungsgebühren im Verhältnis zu Autoren,
                Musikverlagen und/oder Verwertungsgesellschaften (GEMA, GVL
                etc.). Nach Veröffentlichung stellt der Auftraggeber dem
                Komponisten unaufgefordert Belegstücke (bei Film auf Beta
                Format) und die Schaltpläne zur Verfügung. German Wahnsinn
                verpflichtet sich, die Schaltpläne vertraulich zu behandeln und
                nur zum Zwecke des Abgleichs mit der GEMA oder einer anderen
                Urheberrechtsgesellschaft zu verwenden. Eine
                darüberhinausgehende Weitergabe der Schaltpläne erfolgt nicht.
              </p>
              <p>
                5. Der Komponist hat das Recht, sämtliche die Verbreitung seines
                Werkes betreffenden Unterlagen halbjährlich durch eine zur
                Berufsverschwiegenheit verpflichtete Person (vereidigter
                Buchprüfer, Wirtschaftsprüfer, Rechtsanwalt) einsehen zu lassen.
                Die Kosten der Buchprüfung trägt der Komponist, sofern die
                Prüfung keine Fehler ergibt, ansonsten trägt die Kosten der
                Auftraggeber.
              </p>

              <p>C. VORZEITIGE VERTRAGSBEENDIGUNG/ABBRUCH DER ARBEITEN</p>

              <p>
                1. Wird ein erteilter, aber noch nicht begonnener Auftrag aus
                Gründen, die nicht vom Komponisten zu vertreten sind, nicht
                ausgeführt, so kann der Komponist - ohne dass es eines
                Schadensnachweises bedürfte - ein Ausfallhonorar in Höhe von
                mindestens 50% des vereinbarten Honorars berechnen, wobei die
                Geltendmachung eines darüber hinausgehenden Schadens durch den
                Komponisten ausdrücklich unberührt bleibt. Wird ein bereits
                angefangener Auftrag aus von dem Komponisten nicht zu
                vertretenden Gründen nicht fertiggestellt, so steht dem
                Komponisten das volle Honorar zu. Als angefangen gilt ein
                Auftrag, wenn mit der vertraglich geschuldeten Leistung von dem
                Komponisten begonnen wurde. Dem Auftraggeber bleibt der Nachweis
                offen, ein Schaden sei überhaupt nicht entstanden oder
                wesentlich niedriger.
              </p>
              <p>
                2. Der Komponist ist verpflichtet, die zur Ausführung des
                Auftrages erforderlichen Erfüllungsgehilfen mit größtmöglicher
                Sorgfalt auszusuchen. Eine weitergehende Haftung für
                Erfüllungsgehilfen übernimmt der Komponist nicht. Gehen
                Kompositionen trotz größter Sorgfalt des Komponisten unter, ohne
                dass er dies zu vertreten hat, so berührt dies seinen
                Honoraranspruch nicht; er ist in diesem Fall zur
                Ersatzbeschaffung zu einem vom Auftraggeber zu zahlenden
                Selbstkostenpreis verpflichtet, es sei denn, dass der
                Auftraggeber den Untergang zu vertreten hat.
              </p>

              <p>D. EIGENTUMSVORBEHALT</p>
              <p>
                1. Bis zur vollständigen Bezahlung aller Forderungen aus dem
                Vertrag verbleiben das Eigentum an den die Werkaufnahme
                verkörpernden Tonträgern sowie sämtliche Rechte an alle im
                Rahmen des Auftrages erstellten Werken bei dem/der German
                Wahnsinn. Entsprechendes gilt hinsichtlich der Nutzungsrecht an
                den im Rahmen des Vertrages abgelieferten Materialien; bis zur
                vollständigen Bezahlung aller offenen Forderungen aus dem
                Vertrag ist der Auftraggeber also nicht berechtigt, derartige
                Materialien in irgendeiner Form zu verwenden bzw. durch Dritte
                verwenden zu lassen. Bei vertragswidrigem Verhalten des
                Auftraggebers ist German Wahnsinn berechtigt, die Werkaufnahme
                zurückzuverlangen. In der Zurücknahme sowie in der Pfändung der
                Vorbehaltsache liegt kein Rücktritt vom Vertrag.
              </p>
              <p>
                2. Bei Pfändungen oder sonstigen Eingriffen Dritter hat uns der
                Auftraggeber unverzüglich schriftlich zu benachrichtigen.
              </p>

              <p>XI. BESONDERE BESTIMMUNGEN FÜR TONSTUDIO ARBEITEN</p>

              <p>
                Die in Rechnung gestellten Honorare sind Grundhonorare.
                Nutzungs-, Verwertungs-, Leistungsschutz- und/oder abtretbare
                Urheberrechte sowie Urheberrechtsanteile werden von den über
                German Wahnsinn beschäftigten, beauftragten bzw. gebuchten
                Sprechern, Sängern, Komponisten und/oder Darstellern
                grundsätzlich für den Zeitraum von einem Jahr ab Fertigstellung
                und Übergabe an den Auftraggeber für eine bundesweite Auswertung
                (innerhalb der Bundesrepublik Deutschland) übertragen, falls
                nicht anders schriftlich vereinbart.
              </p>

              <p>XII. SCHLUSSBESTIMMUNGEN</p>

              <p>
                1. Erfüllungsort aller Verpflichtungen aus dem
                Vertragsverhältnis ist für beide Teile der Geschäftssitz von
                German Wahnsinn. Ausschließlicher Gerichtsstand ist Hamburg.
              </p>

              <p>
                3. Bei Teilnichtigkeiten einer Bestimmung dieser
                Geschäftsbedingungen bleiben die übrigen Bedingungen wirksam; an
                die Stelle der nichtigen Bedingungen werden die Parteien eine
                Bestimmung vereinbaren, die unter Berücksichtigung der
                wechselseitigen Interessen der Parteien der Branchenüblichkeit
                am nächsten kommt.
              </p>
              <p>
                4. Abweichungen von diesen Allgemeinen Geschäftsbedingungen,
                ergänzende Vereinbarungen und Nebenabreden bedürfen der
                Schriftform oder der ausdrücklichen schriftlichen Bestätigung
              </p>

              <p>Stand: Mai 2014</p>
            </Trans>
          </Copy>
        </LegalSection>
      </LegalContent>
    </Content>
  </Page>
);

const LegalContent = styled.div`
  margin: 8rem 0 0 0;

  ${media.tablet} {
    margin: 5rem 5% 0 5%;
  }

  ${media.laptop} {
    width: 40%;
    margin: 5rem 0 0 5rem;
  }

  a {
    font-family: "NittiGrotesk" !important;
  }

  ul {
    li {
      font-size: 1.1rem;
      list-style-type: disc;
      list-style-position: inside;
    }
  }

  h3 {
    margin: 2rem 0;
  }

  h4 {
    margin: 2rem 0;
  }

  h5 {
    margin: 2rem 0;
  }
`;

const LegalHeading = styled.h1`
  display: none;
  overflow: visible;

  ${media.tablet} {
    display: block;
  }
`;

const LegalSection = styled.section`
  margin-bottom: 5rem;
`;

const Heading = styled.h2``;

const Copy = styled.div`
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 3rem;
`;

const Fish = styled.video`
  display: none;

  ${media.laptop} {
    display: block;
    z-index: -1;
    position: absolute;
    top: -200px;
    right: 20px;
    width: 500px;
  }

  ${media.landscape} {
    display: none;
  }
`;
